import React from 'react';
import videoIcon from '../../img/videoIcon.svg';
import wifiIcon from '../../img/wifiIcon.svg';
import leftArrow from '../../img/leftArrow.svg';
import rightArrow from '../../img/rightArrow.svg';
import rightOrangeArrow from '../../img/rightOrangeArrow.svg';
import orangeDownArrow from '../../img/orange-down-arrow.svg';
import chargerIcon from '../../img/chargeIcon.svg';
import expressIcon from '../../img/express_icon.png';
import noResultsImage from '../../img/noresults.svg';
import eliteIcon from '../../img/eliteIcon.svg';
import standardBusIcon from '../../img/standardBusIcon.svg';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import _ from 'lodash';
import Link from '../Link/Link';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.returningTripsSection = null; // Initialize the ref
    this.setReturningTripsSectionRef = element => {
      this.returningTripsSection = element; // Set the ref
    };  }

  checkExpress(stops) {
    return ((_.findIndex(stops, function(stop) { return stop.stop.city === 'bethesda' }) === -1) && (_.findIndex(stops, function(stop) { return stop.stop.city === 'arlington' }) > -1) && this.props.data.arriveCity !== 'bethesda');
  }

  getBusDescription(type) {
    if(type === 'Elite') {
      return `<ul class="bus-tooltip">
        <li>ELITE Executive coach with 34 seats instead of 56.</li>
        <li>More leg room</li>
        <li>Leather captains chair seating</li>
        <li>Reclining Seats</li>
        <li>Individual trays</li>
        <li>Complimentary movies and tv series on personal device</li>
        <li>Wifi</li>
        <li>Electrical Outlets</li>
      `;
    }
  }

  getDuration(time1, time2) {
    
    var startTime = moment(time1, "HH:mm:ss");
    var endTime = moment(time2, "HH:mm:ss");
    var duration = moment.duration(endTime.diff(startTime));
    var hours = parseInt(duration.asHours());
    var minutes = Math.round(((parseInt(duration.asMinutes())%60)/60) * 100) / 100;
    return ((hours + minutes) + ' - ' + (hours + minutes + 0.5) + ' hours');
  }

  getDurationFromObject(departCity, arriveCity){

    let departCityCode = this.props.getCityCodeFromCityValue(departCity).code
    let arriveCityCode = this.props.getCityCodeFromCityValue(arriveCity).code

    return departCityCode && arriveCityCode ?  this.props.data.durationBetweenCities[departCityCode][arriveCityCode] : ''
  }

  checkDisabled(){
    if(this.props.isRoundTripSelected){
      return (this.props.data.returnDate && this.props.data.returnTrip && this.props.data.departTrip) && !this.props.data.loading ?  false : true;
    }else{
      return (!this.props.data.returnDate && this.props.data.departTrip ) && !this.props.data.loading ?  false : true;
    }

  }
  getStopName (stop) {
    const stopName = this.props.getStopLabel(stop)
    return stopName.split(',')[0]
  }


  render() {
  	return (
  		<div>
        <ReactTooltip />
  			{this.props.data.departCity && this.props.data.arriveCity && this.props.data.departDate && this.props.data.seats && <section id="returning-trip" className="trip-section">
          <div className="container departing-trip-wrap">
            <div className="row">

              {this.props.data.departCity && this.props.data.arriveCity && <div className="col-md-12 departing-trip-title step-a">
                 <p>Departing Trip</p>
                 <div className='grid-3'>
                 <span className="trip-loc">{this.props.getStopLabel(this.props.data.departCity)}</span>

                    <img className='orangeArrow' width="35px" height="48px" src={rightOrangeArrow} alt="to"/>

                  <span className="trip-loc">{this.props.getStopLabel(this.props.data.arriveCity)}</span>
                </div>
              </div>}

              <div className="col-md-12 date-controls">
                <ul className="nav nav-pills nav-fill">
                <li className="date-controls-btn" onClick={this.props.previousWeek.bind(this, 1)}><img src={leftArrow} height="16px" width="8px"/></li>
                  {this.props.data.futureDeparts.map((day, index) => {
                    return (
                      <li className={"nav-item " + (this.props.checkPastDate(day.date) ? "inactive" : "")} key={index}>
                        <a href="javascript:void(0)" onClick={this.props.checkFutureDepart.bind(this, day.date)} className={'nav-link ' + (this.props.checkActiveDay(day.date, this.props.data.departDate) ? 'active' : '')}>
                          <div className="date-controls-day">{day.day}</div>
                          <div className="date-controls-date">{day.date}</div>
                        </a>
                      </li>
                    )
                  })}
                  <li className="date-controls-btn" onClick={this.props.nextWeek.bind(this, 1)}><img src={rightArrow} height="16px" width="8px"/></li>
                </ul>
              </div>
              {this.props.data.loading &&
                <div className="fond">
                  <div className="contener_general">
                    <div className="contener_mixte"><div className="ballcolor ball_1">&nbsp;</div></div>
                    <div className="contener_mixte"><div className="ballcolor ball_2">&nbsp;</div></div>
                    <div className="contener_mixte"><div className="ballcolor ball_3">&nbsp;</div></div>
                    <div className="contener_mixte"><div className="ballcolor ball_4">&nbsp;</div></div>
                  </div>
                </div>
              }
              {this.props.data.trips.departingTrips.length === 0 && !this.props.data.loading &&
                <div className="fond">
                  <div className="no-trip-found">
                    <img src={noResultsImage} />
                    <div className="no-trip-head">No more {this.getStopName(this.props.data.departCity)?.split(",")[0]} to {this.getStopName(this.props.data.arriveCity)} schedules today.</div>
                    <div className="no-trip-msg">Check nearby locations or <a href="tel:+17188349214">call us</a></div>
                  </div>
                </div>

              }
              {this.props.data.trips.departingTrips.length > 0 && <div className="col-md-12 p-0">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12 trip-selector p-0">
                      <div className="container-fluid">
                        <ReactTooltip />
                        {this.props.data.trips.departingTrips.map((bus, index) => {
                          let requiredSDepartStop = this.props.getRequiredStop(bus.stops, this.props.data.departCity)
                          let requiredArrivalStop = this.props.getRequiredStop(bus.stops, this.props.data.arriveCity)
                          return (
                            requiredSDepartStop && requiredArrivalStop && <div key={index} className={'select-trip-row ' + (bus.bus_type.type === 'Express' ? 'has-update-row ' : '') + (bus.cancelled ? 'is-cancelled ' : '') + (bus.bus_type.type === 'Elite' ? 'elite-bus ' : '') + (bus.remainingSeats === 0 ? 'inactive ' : '') + (this.props.data.departTrip && this.props.data.departTrip.schedule_bus === bus.schedule_bus ? 'selected' : '')}>
                              <div className="row cardAccordian">
                                <div className="select-trip-item deptarri-dept" data-toggle="collapse" data-target={'#collapse'+bus.schedule_bus}>
                                  <div className="deptarri-label">DEPARTURE</div>
                                  {requiredSDepartStop && <div className="deptarri-time">{this.props.getTime(requiredSDepartStop.time)}</div>}
                                  <div className="deptarri-loc">{this.props.getStopLabel(this.props.data.departCity)}</div>
                                </div>
                                <div className="select-trip-item" data-toggle="collapse" data-target={'#collapse'+bus.schedule_bus}>
                                  {/* <Link to="">{bus.bus_type.name}</Link> */}
                                  <span className="deptarri-label">{this.getDurationFromObject(this.props.data.departCity, this.props.data.arriveCity)}h<sup>*</sup></span>
                                  <div className="tripArrow"></div>
                                </div>
                                <div className=" select-trip-item deptarri-arri" data-toggle="collapse" data-target={'#collapse'+bus.schedule_bus}>
                                  <div className="deptarri-label">APROX ARRIVAL</div>
                                  {requiredArrivalStop && <div className="deptarri-time">{this.props.getTime(requiredArrivalStop.time)}</div>}
                                  <div className="deptarri-loc">{this.props.getStopLabel(this.props.data.arriveCity)}</div>
                                </div>
                                <div className="select-trip-item deptarri-price" data-toggle="collapse" data-target={'#collapse'+bus.schedule_bus}>
                                    <div className="deptarri-price-amount">${bus.price}<span className="deptarri-price-label">/seat</span></div>
                                  {bus.remainingSeats > 0 && bus.remainingSeats < this.props.data.seats ? <span className="deptarri-seats not-enough">Only {bus.remainingSeats} seat(s) available.</span> : ''}
                                </div>
                                <div className="select-trip-item" data-toggle="collapse" data-target={'#collapse'+bus.schedule_bus}>
                                  {bus.bus_type.type === 'Elite' ? <div className="deptarri-elite" data-html={true} data-tip={this.getBusDescription(bus.bus_type.type)} data-place="top"><img height="20px" src={eliteIcon} /></div>
                                    :
                                    <div className="deptarri-elite"><img height="25px" src={standardBusIcon} /></div>
                                }

                                </div>
                                <div className="select-trip-item">
                                  {!bus.cancelled && bus.remainingSeats > 0 && (!this.props.data.departTrip || (this.props.data.departTrip.schedule_bus !== bus.schedule_bus)) && <a  className={'btn btn-default' + (bus.remainingSeats < this.props.data.seats ? ' disabled' : '')} onClick={(e)=>this.props.selectDepartTrip(bus, e, this.returningTripsSection)}>Select</a>}
                                  {bus.cancelled && <a className="btn btn-default trip-cancelled">CANCELLED</a>}
                                  {bus.remainingSeats <= 0 && <div className="selectTripBtn ml-auto">
                                    <a href="javascript:void(0)" className="btn btn-default sold-out">SOLD OUT</a>
                                  </div>}
                                  {this.props.data.departTrip && this.props.data.departTrip.schedule_bus === bus.schedule_bus && <div className="selectTripBtn">
                                    <a href="javascript:void(0)" className="btn btn-default selected" onClick={this.props.selectDepartTrip.bind(this, bus)}>Selected</a>
                                  </div>}

                                </div>
                                <div className="select-trip-item routeDetailsHeading cursor-pointer" data-toggle="collapse" data-target={'#collapse'+bus.schedule_bus}>

                                  <span>Route Details <img src={orangeDownArrow} height="10px"/></span>

                                </div>


                              </div>
                              <div className="tripDetailsWrapper">
                                {/* {(this.checkExpress(bus.stops) || bus.notes) && <div className="tripDetailFooter">
                                  {this.checkExpress(bus.stops) && <div className="tripExpress"><img src={expressIcon} alt="" /> <span>Express:</span> Skips Bethesda</div>}
                                  {bus.notes && <div className="tripUpdate"><img src={updateIcon} alt="" /> <span>Update:</span> {bus.notes}</div>}
                                </div>} */}
                                <div className="collapse" id={'collapse'+bus.schedule_bus}>
                                  <div className="col-md-12">
                                    <div className="tripDetailsRowWrap">
                                      {bus.stops.map((stop, i) => {
                                        return (<div key={i} className='tripDetailsRow active'>
                                          <div className="time">{moment(stop.time, 'HH:mm:ss').format('hh:mm a')}</div>
                                          <div className="address">{(stop.stop.city)}</div>
                                          <div className="address address2">{stop.stop.address}</div>
                                          <Link className="viewMap" to="/locations" target="_blank" externalLink={true}>View Map</Link>
                                        </div>)
                                      })}
                                    </div>
                                    {/* <button className='btn btn-blue mobile-only close-details' data-toggle="collapse" data-target={'#collapse'+bus.schedule_bus}>Close</button> */}
                                  </div>
                                </div>
                              </div>
                              <div className="features">
                                <span>*subject to traffic, weather and stop locations</span>
                                <ul className="route-details-menu list-inline">
                                    <li className={'route-detail-item list-inline-item ' + (bus.bus_type.hasMovies ? '' : 'not-available')} data-place="top" data-tip={'Movies ' + (bus.bus_type.hasWifi ? 'available' : 'not available')}>
                                      <Link to="#" className="route-detail-link"><img src={videoIcon} alt="" /></Link>
                                    </li>
                                    <li className={'route-detail-item list-inline-item ' + (bus.bus_type.hasWifi ? '' : 'not-available')} data-place="top" data-tip={'Wifi ' + (bus.bus_type.hasWifi ? 'available' : 'not available')}>
                                      <Link to="#" className="route-detail-link"><img src={wifiIcon} alt="" /></Link>
                                    </li>
                                    <li className={'route-detail-item list-inline-item ' + (bus.bus_type.hasPowerOutlets ? '' : 'not-available')} data-place="top" data-tip={'Power outlets ' + (bus.bus_type.hasPowerOutlets ? 'available' : 'not available')}>
                                      <Link to="#" className="route-detail-link"><img src={chargerIcon} alt="" /></Link>
                                    </li>
                                  </ul>
                                </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>

                  </div>
                </div>
              </div>}

            </div>
          </div>
        </section>}

        { this.props.data.returnDate && this.props.data.returnDepartCity && this.props.data.returnArriveCity && this.props.data.departDate && this.props.data.returnDate && this.props.data.seats && <section ref={this.setReturningTripsSectionRef} className={`trip-section ${!this.props.data.departTrip && 'disable-section'}`}>
          <div className="container departing-trip-wrap">
            <div className="row">

              {this.props.data.returnDepartCity && this.props.data.returnArriveCity && <div className="col-md-12 departing-trip-title step-b">
                 {!this.props.data.departTrip ? <p className='not-enough'>Please Select Departing Trip Before Continuing</p> : null}
                 <p>Returning Trip</p>
                 <div className='grid-3'>
                <span className="trip-loc">{this.props.getStopLabel(this.props.data.returnDepartCity)}</span>
                  <img className='orangeArrow' width="35px" height="48px" src={rightOrangeArrow} alt="to"/>
                <span className="trip-loc">{this.props.getStopLabel(this.props.data.returnArriveCity)}</span>
                </div>
              </div>}

              <div className="col-md-12 date-controls alt">

                <ul className="nav nav-pills nav-fill">
                  <li className="date-controls-btn return-back" onClick={this.props.previousWeek.bind(this, 2)}><img src={leftArrow} height="16px" width="8px"/></li>
                  {this.props.data.futureReturns.map((day, index) => {
                    return (
                      <li  className={"nav-item " + (this.props.checkPastDate(day.date) || this.props.checkPastReturnDate(day.date) ? "inactive" : "")} key={index}>
                        <a href="javascript:void(0)" onClick={this.props.checkFutureReturns.bind(this, day.date)} className={'nav-link ' + (this.props.checkActiveDay(day.date, this.props.data.returnDate) ? 'active' : '')}>
                          <div className="date-controls-day">{day.day}</div>
                          <div className="date-controls-date">{day.date}</div>
                        </a>
                      </li>
                    )
                  })}
                  <li className="date-controls-btn return-back" onClick={this.props.nextWeek.bind(this, 2)}><img src={rightArrow} height="16px" width="8px"/></li>
                </ul>
              </div>

              {this.props.data.returnLoading &&
                <div className="fond">
                  <div className="contener_general">
                    <div className="contener_mixte"><div className="ballcolor ball_1">&nbsp;</div></div>
                    <div className="contener_mixte"><div className="ballcolor ball_2">&nbsp;</div></div>
                    <div className="contener_mixte"><div className="ballcolor ball_3">&nbsp;</div></div>
                    <div className="contener_mixte"><div className="ballcolor ball_4">&nbsp;</div></div>
                  </div>
                </div>
              }

              {this.props.data.trips.returningTrips.length === 0 && !this.props.data.returnLoading &&
                <div className="fond">
                  <div className="no-trip-found">
                    <img src={noResultsImage} />
             
                    <div className="no-trip-head">No more {this.getStopName(this.props.data.returnDepartCity)?.split(",")[0]} to {this.getStopName(this.props.data.returnArriveCity)} schedules today.</div>
                    <div className="no-trip-msg">Check nearby locations or <a href="tel:+17188349214">call us</a></div>
                  </div>
                </div>
              }

              {this.props.data.trips.returningTrips.length>0 && <div className="col-md-12 p-0">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12 trip-selector p-0">
                      <div className="container-fluid">
                        {this.props.data.trips.returningTrips.map((bus, index) => {
                          let requiredArrivalStop = this.props.getRequiredStop(bus.stops, this.props.data.returnArriveCity)
                          let requiredDepartureStop = this.props.getRequiredStop(bus.stops, this.props.data.returnDepartCity)
                          let notCompatibleWithDeparture = this.props.data.departTrip && this.props.checkReturnBusTime(this.props.data.returnDate, requiredDepartureStop.time)

                          return (
                            requiredArrivalStop && requiredDepartureStop && <div key={index} 
                            className={
                              `${notCompatibleWithDeparture && 'disable-section '}` +
                              'row select-trip-row alt ' + 
                              (bus.bus_type.type === 'Express' ? 'has-update-row ' : '') + 
                              (bus.cancelled ? 'is-cancelled ' : '') + 
                              (bus.bus_type.type === 'Elite' ? 'elite-bus ' : '') + 
                              (bus.remainingSeats === 0 ? 'inactive ' : '') + 
                              (this.props.data.returnTrip && this.props.data.returnTrip.schedule_bus === bus.schedule_bus ? 'selected' : '') 
                              }>
                              <div className={`row cardAccordian`}>
                                <div className="select-trip-item deptarri-dept" data-toggle="collapse" data-target={'#collapse1'+bus.schedule_bus}>
                                  <div className="deptarri-label">DEPARTURE</div>
                                  {requiredDepartureStop && <div className="deptarri-time">{this.props.getTime(requiredDepartureStop.time)}</div>}
                                  <div className="deptarri-loc">{this.props.getStopLabel(this.props.data.returnDepartCity)}</div>
                                </div>
                                <div className="select-trip-item" data-toggle="collapse" data-target={'#collapse1'+bus.schedule_bus}>
                                   {/* <Link to="">{bus.bus_type.name}</Link> */}
                                   <span className="deptarri-label">{this.getDurationFromObject(this.props.data.returnDepartCity, this.props.data.returnArriveCity)}h<sup>*</sup></span>
                                  <div className="tripArrow"></div>
                                </div>
                                <div className="select-trip-item deptarri-arri" data-toggle="collapse" data-target={'#collapse1'+bus.schedule_bus}>
                                  <div className="deptarri-label">APROX ARRIVAL</div>
                                  {requiredArrivalStop && <div className="deptarri-time">{this.props.getTime(requiredArrivalStop.time)}</div>}
                                  <div className="deptarri-loc">{this.props.getStopLabel(this.props.data.returnArriveCity)}</div>
                                </div>
                                <div className="select-trip-item deptarri-price" data-toggle="collapse" data-target={'#collapse1'+bus.schedule_bus}>

                                  <div className="deptarri-price-amount">${bus.price}<span className="deptarri-price-label">/seat</span></div>
                                  {bus.remainingSeats > 0 && bus.remainingSeats < this.props.data.seats ? <span className="deptarri-seats not-enough">Only {bus.remainingSeats} seat(s) available.</span> : ''}

                                </div>
                                <div className="select-trip-item" data-toggle="collapse" data-target={'#collapse1'+bus.schedule_bus}>
                                  {
                                  bus.bus_type.type === 'Elite' ? <span className="deptarri-elite" data-html={true} data-tip={this.getBusDescription(bus.bus_type.type)} data-place="top"><img height="20px" src={eliteIcon} /></span>
                                  :
                                  <div className="deptarri-elite"><img height="25px" src={standardBusIcon} /></div>

                                  }
                                </div>
                                <div className="select-trip-item">
                                  {(!bus.cancelled && bus.remainingSeats > 0 && (!this.props.data.returnTrip || (this.props.data.returnTrip.schedule_bus !== bus.schedule_bus))) && <a className={'btn btn-default' + (bus.remainingSeats < this.props.data.seats || notCompatibleWithDeparture ? ' disabled' : '')} onClick={this.props.selectReturnTrip.bind(this, bus)}>{notCompatibleWithDeparture ? 'Invalid With Depart Trip' : 'Select'}</a>}
                                  {bus.cancelled && <Link to="#" className="btn btn-default trip-cancelled">CANCELLED</Link>}
                                  {bus.remainingSeats <= 0 && <div className="selectTripBtn ml-auto">
                                    <a href="javascript:void(0)" className="btn btn-default sold-out">SOLD OUT</a>
                                  </div>}
                                  {this.props.data.returnTrip && this.props.data.returnTrip.schedule_bus === bus.schedule_bus && <div className="selectTripBtn">
                                    <a href="javascript:void(0)" className="btn btn-default selected" onClick={this.props.selectReturnTrip.bind(this, bus)}>Selected</a>
                                  </div>}

                                </div>
                                <div className="select-trip-item routeDetailsHeading cursor-pointer" data-toggle="collapse" data-target={'#collapse1'+bus.schedule_bus}>
                                  <span>Route Details <img src={orangeDownArrow} height="10px"/></span>
                                </div>
                              </div>
                              <div className="tripDetailsWrapper">
                                {/* {(this.checkExpress(bus.stops) || bus.notes) && <div className="tripDetailFooter">
                                  {this.checkExpress(bus.stops) && <div className="tripExpress"><img src={expressIcon} alt="" /> <span>Express:</span> Skips Bethesda</div>}
                                  {bus.notes && <div className="tripUpdate"><img src={updateIcon} alt="" /> <span>Update:</span> {bus.notes}</div>}
                                </div>} */}
                                <div className="row collapse" id={'collapse1'+bus.schedule_bus}>
                                  <div className="col-md-12">
                                    <div className="tripDetailsRowWrap">
                                      {bus.stops.map((stop, i) => {
                                        return (<div key={i} className='tripDetailsRow'>
                                          <div className="time">{moment(stop.time, 'HH:mm:ss').format('hh:mm a')}</div>
                                          <div className="address">{stop.stop.city}</div>
                                          <div className="address">{stop.stop.address}</div>
                                          <Link className="viewMap" to="/" target="_blank" externalLink={true}>View Map</Link>
                                        </div>)
                                      })}
                                    </div>
                                    {/* <button className='btn btn-blue mobile-only close-details' data-toggle="collapse" data-target={'#collapse'+bus.schedule_bus}>Close</button> */}
                                  </div>
                                </div>
                              </div>

                              <div className="features">
                                <span>subject to traffic, weather and stop locations</span>

                                <ul className="route-details-menu list-inline">
                                    <li className={'route-detail-item list-inline-item ' + (bus.bus_type.hasMovies ? '' : 'not-available')} data-place="top" data-tip={'Movies ' + (bus.bus_type.hasWifi ? 'available' : 'not available')}>
                                      <Link to="#" className="route-detail-link"><img src={videoIcon} alt="" /></Link>
                                    </li>
                                    <li className={'route-detail-item list-inline-item ' + (bus.bus_type.hasWifi ? '' : 'not-available')} data-place="top" data-tip={'Wifi ' + (bus.bus_type.hasWifi ? 'available' : 'not available')}>
                                      <Link to="#" className="route-detail-link"><img src={wifiIcon} alt="" /></Link>
                                    </li>
                                    <li className={'route-detail-item list-inline-item ' + (bus.bus_type.hasPowerOutlets ? '' : 'not-available')} data-place="top" data-tip={'Power outlets ' + (bus.bus_type.hasPowerOutlets ? 'available' : 'not available')}>
                                      <Link to="#" className="route-detail-link"><img src={chargerIcon} alt="" /></Link>
                                    </li>
                                  </ul>
                                </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>

                  </div>
                </div>
              </div>}

            </div>
          </div>
        </section>}

        {<div className="container getMyTickets">
            {this.props.data.wrongTime && <div className="error">{this.props.data.wrongTime}</div>}
        </div>}
        {/* {((!this.props.data.returnDate && this.props.data.trips.departingTrips.length > 0) || (this.props.data.returnDate && this.props.data.trips.departingTrips.length > 0 && this.props.data.trips.returningTrips.length > 0)) && <div className="container getMyTickets">
            <button disabled={this.checkDisabled()} onClick={this.props.getTickets.bind(this)} className='btn btn-orange' type='button'>Next: Itinerary & Checkout </button>
            {this.props.data.wrongTime && <div className="error">{this.props.data.wrongTime}</div>}
        </div>} */}

        {/* {(!this.props.data.departCity || !this.props.data.arriveCity || !this.props.data.departDate || !this.props.data.seats) &&
          <div className="fond">
            <div className="no-trip-found">
              <img src={noResultsImage} />
              <div className="no-trip-head">No Remaining Schedules for the day</div>
              <div className="no-trip-msg">Try adjusting the filters to find results.</div>
            </div>
          </div>
        } */}
        {/* {(!this.props.data.departCity || !this.props.data.arriveCity || !this.props.data.departDate || !this.props.data.seats) &&
          <div className="covid-wrap">
            <div className="covid-head">COVID-19 NOTICE</div>
            <p>
              Due to continuing spread of COVID-19 we have decided to temporarily suspend our daily schedules. We plan to resume regular operations on June 18th. Schedules for future travel are available online for booking. Your safety is our priority. We have a responsibility to our passengers, employees and community to do our part to prevent the spread of COVID-19. We appreciate your support and understanding and look forward to having you on board soon. We will be issuing a credit for future use in your Tripperbus account.
            </p>
          </div>
        } */}
      </div>
  	)
  }
}

export default Home;


